<template>
  <div class="pb-5">
    <VRow>
      <VCol class="pr-12" cols="6">
        <p v-show="!noTitles" class="form-title mb-2">
          Название опции
        </p>
        <VTextField
          v-model="optionToEdit.title"
          class="form-field"
          color="success"
          outlined
          placeholder="Введите название"
          :readonly="readonly"
          :rules="rules"
        />
      </VCol>
      <VCol class="pr-12" cols="4">
        <p v-show="!noTitles" class="form-title mb-2">
          Цена
        </p>
        <VTextField
          v-model="optionToEdit.price"
          class="form-field price"
          color="success"
          hide-details
          outlined
          :readonly="readonly"
          @keypress="parseNumber"
        >
          <template #append>
            <span class="font-weight-medium"> &#8376; </span>
          </template>
        </VTextField>
      </VCol>
      <VCol v-if="deletable" class="d-flex align-center">
        <RoundButton class="mt-6 ml-4" @click="deleteOption" />
      </VCol>
    </VRow>
    <VRow v-if="editable">
      <VCol>
        <p class="form-title mb-0">
          Статус опции
        </p>
        <StatesChipGroup
          :state="optionToEdit.state"
          @change="optionToEdit.state = $event"
        />
      </VCol>
      <VCol class="d-flex align-end pb-6">
        <VCheckbox
          v-model="optionToEdit.isVisible"
          class="light-grey-checkbox mb-0"
          color="#E43539"
          hide-details
          :ripple="false"
        />
        <span> Отображение в блюде (меню) </span>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import RoundButton from '@/components/RoundButton.vue';
import StatesChipGroup from '@/components/StatesChipGroup.vue';
import { parseNumber } from '@/helpers/price';

export default {
  components: {
    StatesChipGroup,
    RoundButton,
  },
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    noTitles: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'change'],
  data() {
    return {
      optionToEdit: {
        title: '',
        price: '',
        description: '',
        state: 'active',
        image: '',
        isFood: false,
        isOption: true,
        isAlcohol: false,
        modifiers: [],
        isVisible: true,
      },
      parseNumber,
      rules: [
        (value) => !!value || 'Введите название',
        (value) => !this.alreadyExists(value) || 'Опция с таким названием уже существует',
      ],
    };
  },
  mounted() {
    this.setInitialData();
  },
  methods: {
    setInitialData() {
      if (Object.keys(this.option).length) {
        this.optionToEdit = { ...this.option };
      }
    },
    deleteOption() {
      this.$emit('delete');
    },
    alreadyExists(title) {
      return this.options.some((option) => option.title === title);
    },
  },
  watch: {
    optionToEdit: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
    option: {
      handler() {
        this.setInitialData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
