<template>
  <VDataIterator
    hide-default-footer
    :items="sortedItemsPerPage"
    :items-per-page="20"
    no-data-text="Данные отсутствуют"
    no-results-text="Данные отсутствуют"
    :page="page"
  >
    <template v-slot:header>
      <div class="mt-4 d-flex justify-space-between">
        <div class="input mr-12">
          <p class="input-title larger my-2">
            Поиск
          </p>
          <VTextField
            v-model="search"
            clearable
            color="#8c8c8c"
            dense
            no-data-text="События не найдена"
            outlined
            placeholder="Введите название события"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
        <div class="mr-12">
          <p class="input-title larger my-2">
            Создание
          </p>
          <DialogWindow
            :title="dialogTitle"
            @add="addItem"
            @open="createKey = $event"
          >
            <template v-slot:header>
              <UniversalButton
                v-for="(btnTitle, btnIndex) in createBtnTitles"
                :key="btnIndex"
                class="mr-5"
                icon="plus-circle"
                outlined
                style="border-radius: 12px; padding: 0 30px"
                :text="btnTitle"
                x-large
                @click="dialogTitle = btnTitle"
              />
            </template>
            <template v-slot:main>
              <CartEventFood
                :key="createKey"
                :event="null"
                :event-list="itemsPerPage"
                :foods="foods"
                :labels="labels"
                :restaurant-pk="restaurantPk"
                title="Создать событие"
                @change="cartEventToEdit = $event"
              />
            </template>
          </DialogWindow>
        </div>
      </div>
      <div class="mt-2 event-table">
        <VRow class="mx-3 pl-2">
          <VCol class="table-title" cols="4">
            Название
          </VCol>
          <VCol class="table-title" cols="3">
            Статус
          </VCol>
          <VCol class="table-title" cols="3">
            Позиция
          </VCol>
          <VCol class="table-title" cols="2">
            Изменения
          </VCol>
        </VRow>
        <VCard class="my-2" elevation="0">
          <VToolbar
            color="#B7BED0"
            dense
            elevation="0"
          >
            <VRow class="px-2">
              <VCol cols="3">
                <DropDownMenu
                  :items="titleSortOptions"
                  prepend-icon="mdi-magnify"
                  :title="titleSortOptions[0].title"
                  width="90"
                  @change="titleSortDirection = $event.value"
                />
              </VCol>
              <VCol
                align="center"
                cols="3"
                justify="center"
              >
                <DropDownMenu
                  :items="states.cartEventAll"
                  :title="selectedState.title"
                  @change="selectedState = $event"
                />
              </VCol>
            </VRow>
          </VToolbar>
        </VCard>
      </div>
    </template>
    <template v-slot:default="props">
      <VCol
        v-for="(event, eventIndex) in props.items"
        :key="eventIndex"
        class="d-flex justify-space-between py-0"
        cols="12"
      >
        <VRow class="mx-0 py-0 event-table">
          <VCol class="pr-0 mx-0" cols="12">
            <VCard
              class="br-20"
              outlined
              tile
            >
              <VRow class="py-4 px-4" fuild>
                <VCol class="pl-6" cols="3">
                  <DialogWindow
                    is-editing
                    title="Редактирование события"
                    @close="reset"
                    @open="editKey = $event"
                    @update="updateItem"
                  >
                    <template v-slot:header>
                      <span class="title-pointer"> {{ event.title }} </span>
                    </template>
                    <template v-slot:main>
                      <CartEventFood
                        :key="editKey"
                        :event="event"
                        :event-list="itemsPerPage"
                        :foods="foods"
                        :labels="labels"
                        :restaurant-pk="restaurantPk"
                        @change="cartEventToEdit = $event"
                      />
                    </template>
                  </DialogWindow>
                </VCol>
                <VCol
                  align="center"
                  cols="3"
                  justify="center"
                >
                  <DropDownMenu
                    :color="states.colors[event.state]"
                    :items="states.cartEvent"
                    :title="states.titles[event.state]"
                    @select="updateStateOfCartEvent($event, event)"
                  />
                </VCol>
                <VCol
                  align="center"
                  cols="3"
                  justify="center"
                >
                  <span>
                    {{ event.position }}
                  </span>
                </VCol>
                <VCol
                  align="center"
                  cols="3"
                  justify="center"
                >
                  <span>
                    {{ formatDate(event.updatedDt || event.createdDt) }}
                  </span>
                </VCol>
              </VRow>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </template>
    <template v-slot:footer>
      <VPagination
        v-model="page"
        color="#B7BED0"
        :length="totalPage"
        :total-visible="7"
      />
    </template>
  </VDataIterator>
</template>

<script>
import { states } from '@/conditions/states';
import {
  alphabeticalSortOptions, numericalSortOptions, objectsAreSame,
} from '@/helpers/table';
import { stringSortByKey, numberSortByKey } from '@/helpers/sort';
import {
  getCartEventList,
  fetchFoodLabels,
  createCartEvent,
  updateCartEvent,
  updateCartEventWithCondition,
} from '@/api/api';
import DropDownMenu from '@/components/DropDownMenu.vue';
import UniversalButton from '@/components/UniversalButton.vue';
import DialogWindow from '@/components/DialogWindow.vue';
import CartEventFood from '@/components/CartEventFood.vue';
import { formatDate } from '@/helpers/date';

export default {
  name: 'FoodEventsTable',
  props: {
    restaurantPk: {
      type: String,
      default: null,
    },
    foods: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CartEventFood,
    DialogWindow,
    UniversalButton,
    DropDownMenu,
  },
  data() {
    return {
      formatDate,
      states,
      itemsPerPage: [],
      search: null,
      page: 1,
      totalPage: 0,
      selectedState: { title: 'Все', value: null },
      dialogTitle: null,
      createBtnTitles: [
        'Событие',
      ],
      titleSortOptions: alphabeticalSortOptions,
      titleSortDirection: 'descending',
      scoreSortOptions: numericalSortOptions,
      scoreSortDirection: 'ascending',
      labels: [],
      cartEventToEdit: null,
      createKey: 0,
      editKey: 0,
    };
  },
  created() {
    this.getItems();
    fetchFoodLabels({ limit: 5000 }).then((response) => {
      this.labels = response.data;
    });
  },
  computed: {
    params() {
      const params = {};
      params.restaurant_id = this.restaurantPk;
      params.title = this.search;
      params.page = this.page;
      params.state = this.selectedState.value;
      return params;
    },
    sortedItemsPerPage() {
      const items = this.itemsPerPage;
      const sortedByTitleItems = items.sort(stringSortByKey('title')[this.titleSortDirection]);
      const sortedByScoreItems = sortedByTitleItems.sort(numberSortByKey('score')[this.scoreSortDirection]);
      return sortedByScoreItems.sort(numberSortByKey('position').descending);
    },
  },
  methods: {
    getItems() {
      this.cartEventToEdit = null;
      getCartEventList(this.params).then((response) => {
        this.itemsPerPage = response.cartevents;
        this.totalPage = response.total_page;
      });
    },
    addItem() {
      this.cartEventToEdit.restaurant_id = this.restaurantPk;
      createCartEvent(this.cartEventToEdit).then(() => {
        this.getItems();
      });
    },
    updateItem() {
      updateCartEventWithCondition(this.cartEventToEdit, this.cartEventToEdit.id)
        .then(() => {
          this.getItems();
        });
    },
    updateStateOfCartEvent(state, cartEvent) {
      if (state !== undefined && state.value !== cartEvent.state) {
        const cartEventUpdate = cartEvent;
        cartEventUpdate.state = state.value;
        updateCartEvent(cartEventUpdate, cartEventUpdate.id).then(() => {
          this.getItems();
        });
      }
    },
    reset() {
      this.cartEventToEdit = null;
    },
  },
  watch: {
    restaurantPk: {
      immediate: false,
      handler() {
        this.getItems();
      },
    },
    params: {
      immediate: false,
      deep: false,
      handler(newVal, oldVal) {
        if (newVal.page === oldVal.page) {
          this.page = 1;
        }
        if (!objectsAreSame(newVal, oldVal)) {
          this.getItems();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
