<template>
  <VDataIterator
    hide-default-footer
    :items="sortedItemsPerPage"
    :items-per-page="15"
    no-data-text="Данные отсутствуют"
    no-results-text="Данные отсутствуют"
    :page.sync="page"
  >
    <template v-slot:header>
      <div class="mt-4 d-flex justify-space-between">
        <div class="input">
          <p class="input-title my-2">
            Поиск
          </p>
          <VAutocomplete
            v-model="search"
            append-icon="mdi-chevron-down"
            clearable
            color="#8c8c8c"
            dense
            item-text="title"
            item-value="id"
            :items="items"
            no-data-text="Блюдо не найдено"
            outlined
            placeholder="Введите название блюда"
            prepend-inner-icon="mdi-magnify"
          >
          </VAutocomplete>
        </div>
        <div class="ml-12">
          <p class="input-title my-2 ml-5">
            Создание
          </p>
          <DialogWindow
            :title="dialogTitle"
            @add="addItem"
            @open="key = $event"
          >
            <template v-slot:header>
              <UniversalButton
                v-for="(btnTitle, index) in createBtnTitles"
                :key="index"
                class="ml-5 my-1"
                icon="plus-circle"
                outlined
                style="border-radius: 12px; padding: 0 30px"
                :text="btnTitle"
                x-large
                @click="dialogTitle = btnTitle"
              />
            </template>
            <template v-slot:main>
              <Food
                v-if="dialogTitle === 'Создать блюдо'"
                :key="key"
                :foods="items"
                :restaurant-pk="restaurantPk"
                @change="foodOrOptionToEdit = $event"
              />
              <ModifierOption
                v-if="dialogTitle === 'Создать опцию модификатора'"
                :key="key"
                :options="items"
                @change="foodOrOptionToEdit = $event"
              />
            </template>
          </DialogWindow>
        </div>
      </div>
      <div class="mt-2">
        <div class="d-flex">
          <div class="table-title food-col-id">
            id
          </div>
          <div class="table-title food-col-title">
            Название
          </div>
          <div class="table-title food-col-price">
            Цена
          </div>
          <div class="table-title food-col-description">
            Описание
          </div>
          <div class="table-title food-col-state">
            Статус
          </div>
          <div class="table-title food-col-category">
            Категория
          </div>
          <div class="table-title food-col-type">
            Тип
          </div>
          <div class="table-title food-col-orders-per-week">
            Заказов <br> за неделю
          </div>
          <div class="table-title food-col-changes">
            Изменения
          </div>
          <div class="table-title food-col-food-labels">
            Метки
          </div>
          <div class="table-title food-col-position">
            Позиция
          </div>
        </div>
        <VCard
          class="my-2 border-2"
          color="#B7BED0"
          elevation="0"
        >
          <div class="d-flex w-full">
            <div class="food-col-id" />
            <div class="food-col-title">
              <DropDownMenu
                :items="titleSortOptions"
                prepend-icon="mdi-magnify"
                :title="titleSortOptions[0].title"
                width="90"
                @change="titleSortDirection = $event.value"
              />
            </div>
            <div class="food-col-price" />
            <div class="food-col-description" />
            <div class="food-col-state">
              <DropDownMenu
                :items="states.all"
                :title="selectedState.title"
                @change="selectedState = $event"
              />
            </div>
            <div class="food-col-category">
              <DropDownMenu
                :items="categories"
                :title="selectedCategory.title"
                width="150"
                @change="selectedCategory = $event"
              />
            </div>
            <div class="food-col-type">
              <DropDownMenu
                :items="types"
                :title="selectedType.title"
                @change="selectedType = $event"
              />
            </div>
            <div class="food-col-orders-per-week">
              <DropDownMenu
                :items="scoreSortOptions"
                :title="scoreSortOptions[0].title"
                @change="scoreSortDirection = $event.value"
              />
            </div>
            <div class="food-col-changes" />
            <div class="food-col-food-labels">
              <FoodLabelsSelect
                :items="labels"
                :title="selectedLabel.title"
                @change="selectedLabel = $event"
              />
            </div>
            <div class="food-col-position" />
          </div>
        </VCard>
      </div>
    </template>
    <template v-slot:default="props">
      <VRow class="mx-0">
        <VCol
          v-for="food in props.items"
          :key="food.id"
          cols="12"
        >
          <VCard
            class="br-20"
            outlined
            tile
          >
            <div class="d-flex font-12 py-2">
              <div class="food-col-id">
                {{ food.id }}
              </div>
              <div class="food-col-title">
                <DialogWindow
                  is-editing
                  :title="getDialogTitle(food.isOption)"
                  @open="key = $event"
                  @update="updateItem"
                >
                  <template v-slot:header>
                    <span class="title-pointer"> {{ food.title }} </span>
                  </template>
                  <template v-slot:main>
                    <ModifierOption
                      v-if="!food.isFood"
                      :key="key"
                      editable
                      :option="food"
                      @change="foodOrOptionToEdit = $event"
                    />
                    <Food
                      v-else
                      :key="key"
                      editable
                      :food="food"
                      :restaurant-pk="restaurantPk"
                      @change="foodOrOptionToEdit = $event"
                    />
                  </template>
                </DialogWindow>
              </div>
              <div class="food-col-price">
                <span class="font-weight-bold">
                  {{ food.formattedPrice }}
                </span>
              </div>
              <div class="food-col-description">
                <span> {{ food.description ? food.description : '' }} </span>
              </div>
              <div class="food-col-state">
                <DropDownMenu
                  :color="states.colors[food.state]"
                  font-size="14"
                  :items="states.food"
                  :title="states.titles[food.state]"
                  @select="updateStateOfFood($event, food)"
                />
              </div>
              <div class="food-col-category">
                <span> {{ getItemCategories(food || []) }} </span>
              </div>
              <div class="food-col-type flex-wrap">
                <span v-show="food.isFood"> Блюдо </span>
                <span v-show="food.isFood && food.isOption" class="mx-1"> / </span>
                <span v-show="food.isOption"> Опция модификатора </span>
              </div>
              <div class="food-col-orders-per-week text-center">
                {{ food.score }}
              </div>
              <div class="food-col-changes">
                <VCard
                  :class="{ 'green--text font-weight-bold': food.isRecentlyUpdated }"
                  flat
                  width="80"
                >
                  {{ food.updatedDt ? food.updatedDt : food.createdDt }}
                </VCard>
              </div>
              <div class="food-col-food-labels">
                <span v-if="food.labels.length > 0">{{ getLabelsTitleList(food.labels) }}</span>
              </div>
              <div class="food-col-position">
                <span v-if="food.position !== undefined">{{ food.position }}</span>
              </div>
            </div>
          </VCard>
        </VCol>
      </VRow>
    </template>
    <template v-slot:footer>
      <VPagination
        v-model="page"
        color="#B7BED0"
        :length="totalPage"
        :total-visible="7"
      />
    </template>
  </VDataIterator>
</template>

<script>
import { states } from '@/conditions/states';
import {
  alphabeticalSortOptions, numericalSortOptions, typeOptions, objectsAreSame,
} from '@/helpers/table';
import { stringSortByKey, numberSortByKey } from '@/helpers/sort';
import {
  fetchFoodsAndOptions, createFoodOrOption, updateFoodOrOption, deleteFoodOrOption,
} from '@/api/api';
import Food from '@/components/Food.vue';
import DropDownMenu from '@/components/DropDownMenu.vue';
import UniversalButton from '@/components/UniversalButton.vue';
import DialogWindow from '@/components/DialogWindow.vue';
import ModifierOption from '@/components/ModifierOption.vue';
import FoodLabelsSelect from '@/components/FoodLabelsSelect.vue';

export default {
  components: {
    DialogWindow,
    ModifierOption,
    Food,
    DropDownMenu,
    UniversalButton,
    FoodLabelsSelect,
  },
  props: {
    restaurantPk: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['fetch-items'],
  data() {
    return {
      states,
      itemsPerPage: [],
      categories: [{ title: 'Все' }],
      dialogTitle: null,
      search: null,
      selectedState: { title: 'Все', value: 'all' },
      selectedCategory: { title: 'Все' },
      selectedType: { title: 'Все', value: 'all' },
      createBtnTitles: [
        'Создать блюдо',
        'Создать опцию модификатора',
      ],
      titleSortOptions: alphabeticalSortOptions,
      titleSortDirection: 'descending',
      scoreSortOptions: numericalSortOptions,
      scoreSortDirection: 'ascending',
      types: typeOptions.food,
      page: 1,
      totalPage: 0,
      foodOrOptionToEdit: null,
      key: 0,
      labels: [{ title: 'Без меток' }],
      selectedLabel: { title: 'Без меток' },
    };
  },
  computed: {
    params() {
      return {
        page: this.page,
        foods: this.search,
        states: this.selectedState.value,
        categories: this.selectedCategory.id || null,
        label_id: this.selectedLabel.id,
        [this.selectedType.value]: true,
      };
    },
    sortedItemsPerPage() {
      const items = this.itemsPerPage;
      const sortedByTitleItems = items.sort(stringSortByKey('title')[this.titleSortDirection]);
      const sortedByScoreItems = sortedByTitleItems.sort(numberSortByKey('score')[this.scoreSortDirection]);
      return sortedByScoreItems.sort(numberSortByKey('position').descending);
    },
  },
  methods: {
    async getItems() {
      this.$emit('fetch-items');
      await fetchFoodsAndOptions(this.restaurantPk, this.params).then((response) => {
        this.itemsPerPage = response.items;
        this.totalPage = response.total_page;
      });
      await this.setLabels();
      await this.setCategories();
    },
    addItem() {
      createFoodOrOption(this.restaurantPk, this.foodOrOptionToEdit).then(() => {
        this.getItems();
      }).finally(() => {
        this.foodOrOptionToEdit = null;
      });
    },
    updateItem() {
      updateFoodOrOption(this.restaurantPk, this.foodOrOptionToEdit).then(() => {
        this.getItems();
      });
    },
    deleteItem(id) {
      deleteFoodOrOption(this.restaurantPk, id).then(() => {
        this.getItems();
      });
    },
    getItemCategories(item) {
      return item.categories.map((category) => category.title).join(', ');
    },
    setCategories() {
      this.categories = [{ title: 'Все' }];
      this.items.forEach((item) => item.categories.forEach((category) => {
        if (category.title) {
          this.categories.push({ id: category.id, title: category.title });
        }
      }));
      this.categories = this.categories
        .filter((value, index, self) => index === self.findIndex((t) => (
          t.id === value.id && t.title === value.title
        )));
    },
    getDialogTitle(isOption) {
      return isOption ? 'Редактировать опцию модификатора' : 'Редактировать блюдо';
    },
    async updateStateOfFood(state, food) {
      if (state !== undefined && state.value !== food.state) {
        const foodUpdate = food;
        foodUpdate.state = state.value;
        await updateFoodOrOption(this.restaurantPk, foodUpdate);
        await this.getItems();
      }
    },
    getLabelsTitleList(list) {
      return String(list.map((item) => item.title)).replace(/,/g, ', ');
    },
    setLabels() {
      this.items.forEach((item) => {
        if (item.labels.length > 0) {
          item.labels.forEach((label) => {
            const filtered = this.labels.filter((element) => element.id === label.id);
            if (filtered.length === 0) {
              this.labels.unshift(label);
            }
          });
        }
      });
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.setCategories();
      },
    },
    restaurantPk: {
      immediate: false,
      handler() {
        this.getItems();
      },
    },
    params: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal.page === oldVal.page) {
          this.page = 1;
        }
        if (!objectsAreSame(newVal, oldVal)) {
          this.getItems();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

.v-input--selection-controls__input {
  display: none;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin: 0;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  min-width: 20px;
}
.v-chip {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn-drop {
  font-size: 28px;
  color:#9B9B9B;
  font-weight: 100;
}
.btn-clear {
  margin-top: 4px;
  font-size: 18px;
  color:#9B9B9B;
  &:hover {
    cursor: pointer;
  }
}
</style>
