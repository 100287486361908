<template>
  <div>
    <VDivider />
    <VCard
      class="overflow-auto"
      flat
      max-height="62vh"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
      >
        <VDivider v-show="index > 0" />
        <div class="d-flex mt-6">
          <VCheckbox
            v-model="selectedOptions"
            class="mt-2 mx-3"
            color="success"
            :value="option"
          />
          <ModifierOption
            no-titles
            :option="option"
            readonly
          />
        </div>
      </div>
      <p v-if="!options.length" class="my-3">
        Доступные опции для модификатора отсутствуют
      </p>
    </VCard>
    <VDivider class="mb-6" />
  </div>
</template>

<script>
import ModifierOption from '@/components/ModifierOption.vue';

export default {
  components: { ModifierOption },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  data() {
    return {
      selectedOptions: [],
    };
  },
  watch: {
    selectedOptions: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
