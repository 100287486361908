<template>
  <div>
    <VRow class="pb-5">
      <VCol class="py-0" cols="6">
        <div>
          <p class="form-title mb-2">
            Название*
          </p>
          <VTextField
            v-model="foodEventEdit.title"
            class="form-field type-text"
            color="success"
            outlined
            placeholder="Например: Событие"
            :rules="rulesTitle"
          />
        </div>
        <div>
          <p class="form-title mb-2">
            Подсказка
          </p>
          <VTextarea
            v-model="foodEventEdit.disclaimer"
            class="form-field type-text"
            color="success"
            outlined
            placeholder="Введите подсказку"
            rows="6"
          />
        </div>
      </VCol>
      <VCol class="py-0" cols="6">
        <div>
          <p class="form-title mb-2">
            Позиция
          </p>
          <VTextField
            class="form-field"
            color="success"
            outlined
            :value="foodEventEdit.position"
            @input="foodEventEdit.position = $event !== '' || $event !== null ? $event : 0"
            @keypress="parseNumber"
          >
          </VTextField>
        </div>
        <div v-if="event != null" class="my-2">
          <p class="form-title mb-0">
            Статус блюда
          </p>
          <StatesChipGroup
            :state="foodEventEdit.state"
            :type="'cartEvent'"
            @change="foodEventEdit.state = $event"
          />
        </div>
      </VCol>
    </VRow>
    <VDivider />
    <VRow style="height:167px">
      <VCol
        class="py-0"
        cols="6"
        style="margin: auto 0;"
      >
        <div>
          <p class="form-title mb-2">
            Блюдо*
          </p>
          <DialogWindow title="Выбрать блюдо" @add="updateFoodSelect">
            <template #header>
              <VBtn
                class="default-btn add-btn"
                color="success"
                x-large
              >
                {{ foodEventEdit.foodId ? 'Изменить блюдо' : 'Выбрать блюдо' }}
              </VBtn>
            </template>
            <template #main>
              <VCard class="selected-dialog" flat>
                <div class="mx-3 mb-12 pb-12">
                  <p class="form-title mb-2">
                    Блюдо
                  </p>
                  <VAutocomplete
                    v-model="selectedFood"
                    append-icon="mdi-chevron-down"
                    attach
                    class="form-field autocomplete-width"
                    color="success"
                    item-text="title"
                    :items="foods"
                    :menu-props="menuPropsFood"
                    no-data-text="Блюдо не найдено"
                    outlined
                    placeholder="Введите название или выберите"
                    prepend-inner-icon="mdi-magnify"
                    return-object
                  />
                </div>
              </VCard>
            </template>
          </DialogWindow>
        </div>
      </VCol>
      <VCol
        v-if="foodEventEdit.foodId"
        class="py-0"
        cols="6"
      >
        <VRow class="my-0 py-0 ml-2">
          <VCol class="py-0 px-0 selected-info" cols="6">
            <p>{{ getFoodDetails('title') }}</p>
            <p>{{ getFoodDetails('price') }} ₸</p>
          </VCol>
          <VCol class="py-0 px-0 selected-image" cols="6">
            <img
              alt="food_details"
              class="image"
              height="167px"
              :src="getFoodDetails('image')"
              width="229px"
            >
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VDivider class="mb-6" />
    <VRow>
      <VCol
        class="py-0"
        cols="6"
        style="margin: auto 0;"
      >
        <div class="d-flex">
          <p class="form-title my-auto">
            Условия*
          </p>
          <VBtn
            class="white--text rounded-btn mx-5 px-5 subtitle-2"
            color="#4caf50"
            elevation="1"
            @click="addNewCondition"
          >
            <VIcon left>
              mdi-plus-circle
            </VIcon>
            Добавить условие
          </VBtn>
        </div>
      </VCol>
    </VRow>
    <VDivider class="mt-5" />
    <VRow>
      <div class="list">
        <TransitionGroup name="flip-list" tag="div">
          <li
            v-for="(item, i) in foodEventEdit.conditions"
            :key="item+i"
            class="item"
            :class="{
              over: (item === over.item && item !== dragFrom),
              [over.dir]: (item === over.item && item !== dragFrom)
            }"
            :draggable="dragable"
            @dragend="(e) => finishDrag(item, i, e)"
            @dragover="(e) => onDragOver(item, i, e)"
            @dragstart="(e) => startDrag(item, i, e)"
          >
            <VRow class="mx-0 py-0" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
              <VCol class="draggable mx-0 px-0 d-flex justify-start" cols="1">
                <VImg
                  contain
                  height="20px"
                  :src="dragndropIcon"
                  width="10px"
                  @mouseleave="dragable = false"
                  @mouseover="dragable = true"
                />
                <span class="px-0 mx-0">{{ item.position }}</span>
              </VCol>
              <VCol class="pr-0 mx-0" cols="11">
                <VRow class="my-0 py-0">
                  <VCol class="my-0 py-0" cols="10">
                    <VRow class="d-flex justify-space-between">
                      <VCol cols="5">
                        <div>
                          <p class="form-title mb-2">
                            Цена корзины
                          </p>
                          <div class="d-flex justify-space-between">
                            <VTextField
                              v-model="item.minOrderSum"
                              class="form-field price"
                              color="success"
                              hide-details
                              outlined
                              @keypress="parseNumber"
                              @paste.prevent
                            >
                              <template #append>
                                <span class="font-weight-medium"> &#8376; </span>
                              </template>
                            </VTextField>
                            <div class="hyphen" />
                            <VTextField
                              v-model="item.maxOrderSum"
                              class="form-field price"
                              color="success"
                              hide-details
                              outlined
                              @keypress="parseNumber"
                              @paste.prevent
                            >
                              <template #append>
                                <span class="font-weight-medium"> &#8376; </span>
                              </template>
                            </VTextField>
                          </div>
                        </div>
                      </VCol>
                      <VCol cols="6">
                        <div>
                          <p class="form-title mb-2">
                            Тип условий меток блюд
                          </p>
                          <VSelect
                            v-model="item.tagsTypes"
                            append-icon="mdi-chevron-down"
                            attach
                            class="form-field autocomplete-width"
                            color="success"
                            hide-details
                            item-text="title"
                            item-value="value"
                            :items="types"
                            no-data-text="Тип условий не найдена"
                            outlined
                            placeholder="Выберите тип из списка"
                            return-object
                          />
                        </div>
                      </VCol>
                    </VRow>
                    <VRow class="d-flex justify-space-between">
                      <VCol cols="5">
                        <div>
                          <p class="form-title mb-2">
                            Количество
                          </p>
                          <div class="d-flex justify-space-between">
                            <div class="type-text">
                              <VTextField
                                v-model="item.minLimit"
                                class="form-field"
                                color="success"
                                hide-details
                                outlined
                                @keypress="parseNumber"
                                @paste.prevent
                              />
                              <span class="under-label">Мин. значение</span>
                            </div>
                            <div class="hyphen" />
                            <div class="type-text">
                              <VTextField
                                v-model="item.maxLimit"
                                class="form-field"
                                color="success"
                                hide-details
                                outlined
                                @keypress="parseNumber"
                                @paste.prevent
                              />
                              <span class="under-label">Макс. значение</span>
                            </div>
                          </div>
                          <div class="d-flex justify-space-between mt-2 w-25">
                            <div class="default-section">
                              <VTextField
                                v-model="item.defaultLimit"
                                class="form-field"
                                color="success"
                                hide-details
                                outlined
                                @keypress="parseNumber"
                                @paste.prevent
                              />
                              <span class="under-label">По умолчанию</span>
                            </div>
                          </div>
                        </div>
                      </VCol>
                      <VCol cols="6">
                        <div>
                          <p class="form-title mb-2">
                            Метки блюд
                          </p>
                          <VAutocomplete
                            v-model="item.tags"
                            append-icon="mdi-chevron-down"
                            attach
                            auto
                            class="form-field autocomplete-width"
                            color="success"
                            deletable-chips
                            hide-details
                            hide-selected
                            item-text="title"
                            :items="labels"
                            :menu-props="menuProps"
                            multiple
                            no-data-text="Метка не найдена"
                            outlined
                            placeholder="Введите название или выберите"
                            prepend-inner-icon="mdi-magnify"
                            return-object
                            small-chips
                          />
                        </div>
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol class="trash-icon" cols="2">
                    <VBtn
                      color="white"
                      elevation="0"
                      fab
                      hide-details
                      max-height="35"
                      max-width="35"
                      @click="deleteCondition(i, item)"
                    >
                      <VImg
                        contain
                        height="45px"
                        :src="trashWithBorder"
                        width="45px"
                      />
                    </VBtn>
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
          </li>
        </TransitionGroup>
      </div>
    </VRow>
  </div>
</template>

<script>
import { states } from '@/conditions/states';
import { deleteCartEventCondition, getCartEventConditionList } from '@/api/api';
import { parseNumber } from '@/helpers/price';
import DialogWindow from '@/components/DialogWindow.vue';
import StatesChipGroup from '@/components/StatesChipGroup.vue';
import dragndropIcon from '@/assets/icons/dragndrop.svg';
import trashWithBorder from '@/assets/icons/trashWithBorder.svg';

export default {
  components: {
    StatesChipGroup,
    DialogWindow,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Array,
    },
    restaurantPk: {
      type: [Number, String],
      default: null,
    },
    eventList: {
      type: Array,
      default: () => [],
    },
    foods: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  data() {
    return {
      parseNumber,
      trashWithBorder,
      dragndropIcon,
      foodEventEdit: {
        title: '',
        disclaimer: '',
        position: 0,
        state: 'active',
        foodId: null,
        restaurant_id: null,
        terms: [],
        conditions: [],
      },
      selectedFood: null,
      over: {},
      startLoc: 0,
      dragging: false,
      dragable: false,
      dragFrom: {},
      types: states.tagsType,
      menuProps: {
        offsetY: true,
        top: true,
      },
      menuPropsFood: {
        maxHeight: 204,
        offsetY: true,
      },
      rulesTitle: [
        (value) => !!value || 'Введите название',
        (value) => !this.alreadyExists(value) || 'Событие с таким названием уже существует',
      ],
    };
  },
  created() {
    if (this.event !== null) {
      this.foodEventEdit = { ...this.event };
      const selected = this.foods.filter((item) => item.id === this.event.foodId);
      this.selectedFood = selected.length > 0 ? selected[0] : null;
      this.getCartEventConditions();
    } else {
      this.addNewCondition();
    }
  },
  methods: {
    getCartEventConditions() {
      if (this.event) {
        getCartEventConditionList(this.event.id).then((conditions) => {
          if (conditions.length > 0) {
            const temporalConditions = conditions;
            temporalConditions.forEach((item) => {
              if (item.tags.length > 0) {
                item.tags.forEach((tag, index) => {
                  // eslint-disable-next-line eqeqeq
                  const selected = this.labels.filter((element) => element.title == tag);
                  if (selected.length > 0) {
                    // eslint-disable-next-line no-param-reassign
                    item.tags[index] = selected[0];
                  }
                });
              }
            });
            this.foodEventEdit.conditions = temporalConditions;
          }
        });
      }
    },
    deleteCondition(position, item) {
      if (this.foodEventEdit.conditions.length > 1) {
        if (item.id !== 0 || this.foodEventEdit.id !== undefined) {
          deleteCartEventCondition(this.foodEventEdit.id, item.id).then(() => {
            this.foodEventEdit.conditions.splice(position, 1);
          });
        } else {
          this.foodEventEdit.conditions.splice(position, 1);
        }
      }
      this.updateConditionPosition();
    },
    addNewCondition() {
      const condition = {
        id: 0,
        minOrderSum: null,
        maxOrderSum: null,
        minLimit: '0',
        maxLimit: '1',
        defaultLimit: '1',
        tags: [],
        tagsTypes: { value: 'or', title: 'Одна из' },
      };
      this.foodEventEdit.conditions.push(condition);
      this.updateConditionPosition();
    },
    updateFoodSelect() {
      if (this.selectedFood) {
        this.foodEventEdit.foodId = this.selectedFood.id;
      }
    },
    startDrag(item, e) {
      this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = item;
    },
    finishDrag(item, pos) {
      this.foodEventEdit.conditions.splice(pos, 1);
      this.foodEventEdit.conditions.splice(this.over.pos, 0, item);
      this.over = {};
      this.updateConditionPosition();
    },
    onDragOver(item, pos, e) {
      const dir = (this.startLoc < e.clientY) ? 'down' : 'up';
      setTimeout(() => {
        this.over = { item, pos, dir };
      }, 50);
    },
    updateConditionPosition() {
      if (this.foodEventEdit.conditions.length > 0) {
        const conditions = [...[], ...this.foodEventEdit.conditions];
        conditions.forEach((element, index) => {
          // eslint-disable-next-line no-param-reassign
          element.position = index + 1;
        });
        this.foodEventEdit.conditions = conditions;
      }
    },
    alreadyExists(title) {
      const restPk = this.foodEventEdit.restaurant_id
        ? this.foodEventEdit.restaurant_id.toString()
        : null;
      return this.eventList.some((event) => event.title === title)
        && this.restaurantPk === restPk
        && !('id' in this.foodEventEdit);
    },
    getFoodDetails(key) {
      const selected = this.foods.find((item) => item.id === this.foodEventEdit.foodId);
      return selected ? selected[key] : '';
    },
  },
  watch: {
    foodEventEdit: {
      immediate: false,
      deep: true,
      handler() {
        this.$emit('change', this.foodEventEdit);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(120,120,120);
  border-radius: 10px;
}
.selected-info {
  text-align: left;
  margin: auto 0;
}
.selected-image {
  max-width: fit-content;
  width: 229px;
  height: 167px;
}
.draggable {
  margin: auto 0 auto 10px;
  &:hover {
    cursor: pointer;
  }
  span {
    width: 30px;
    font-size: 15px;
    line-height: 18px;
  }
}
.list > div {
    display: flex;
    flex-direction: column;
}
.item {
  outline: none;
  display: inline-block;
  transition: opacity .3s ease-in-out;
}
.flip-list-move {
  transition: transform .2s;
}
.hyphen {
  border: none !important;
  &::after {
    display: block;
    text-align: center;
    font-size: 30px;
    margin: auto 15px;
    color: #C7CACE;
    content: "—";
    border-radius: 25%;
    border: none !important;
  }
}
.trash-icon {
  align-self: center;
  padding-left: 55px;
  &:first-child {
    &:hover {
      cursor: pointer;
    }
  }
}
.under-label {
  color: #212121;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.3px;
  margin-top: 2px;
  margin-left: 2px;
}
.default-section {
  width: 113px;
}
</style>
