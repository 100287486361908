<template>
  <div>
    <div class="d-flex">
      <div class="mr-8">
        <p class="form-title mb-2">
          Название модификатора
        </p>
        <VTextField
          v-model="modifierToEdit.title"
          class="form-field type-text"
          color="success"
          outlined
          placeholder="Например: Выберите котлету для бургера"
          :rules="rules"
        />
      </div>
      <div v-if="editable">
        <p class="form-title mb-0">
          Статус модификатора
        </p>
        <StatesChipGroup
          :state="modifierToEdit.state"
          @change="modifierToEdit.state = $event"
        />
      </div>
    </div>
    <div v-if="modifierToEdit.options.length">
      <VDivider class="mt-5" />
      <div class="d-flex justify-space-between mt-5 mb-1">
        <p class="form-title">
          Опции модификатора
        </p>
        <p class="form-title mr-12">
          Количество опций: <b>{{ modifierToEdit.options.length }}</b>
        </p>
      </div>
      <TransitionGroup name="flip-list" tag="div">
        <li
          v-for="(item, i) in modifierToEdit.options"
          :key="item + i"
          class="item"
          :class="{
            over: (item === over.item && item !== dragFrom),
            [over.dir]: (item === over.item && item !== dragFrom),
          }"
          :draggable="dragable"
          @dragend="(e) => finishDrag(item, i, e)"
          @dragover="(e) => onDragOver(item, i, e)"
          @dragstart="(e) => startDrag(item, i, e)"
        >
          <VDivider />
          <VRow class="mx-0 pt-4 pb-3">
            <VCol class="draggable mx-0 py-0 d-flex justify-start" cols="1">
              <VImg
                contain
                height="20px"
                :src="dragndropIcon"
                width="10px"
                @mouseleave="dragable = false"
                @mouseover="dragable = true"
              />
            </VCol>
            <VCol class="pr-0 mx-0 py-0 px-0" cols="11">
              <ModifierOption
                deletable
                :option="item"
                readonly
                @delete="deleteOption(i, item)"
              />
            </VCol>
          </VRow>
        </li>
      </TransitionGroup>
    </div>
    <VDivider class="mb-5" />
    <VRow class="mt-3 mb-8" justify="center">
      <DialogWindow
        :title="addOptionTitle"
        @add="updateModifierOptions"
        @open="key = $event"
      >
        <template v-slot:header>
          <UniversalButton
            icon="plus-circle"
            outlined
            style="border-radius: 12px; width: 281px;"
            :text="addOptionTitle"
            x-large
          />
        </template>
        <template v-slot:main>
          <ModifierOptionSelection
            :key="key"
            :options="options"
            @change="selectedOptions = $event"
          />
        </template>
      </DialogWindow>
    </VRow>
  </div>
</template>

<script>
import { fetchOptions } from '@/api/api';
import UniversalButton from '@/components/UniversalButton.vue';
import DialogWindow from '@/components/DialogWindow.vue';
import ModifierOption from '@/components/ModifierOption.vue';
import ModifierOptionSelection from '@/components/ModifierOptionSelection.vue';
import StatesChipGroup from '@/components/StatesChipGroup.vue';
import dragndropIcon from '@/assets/icons/dragndrop.svg';

export default {
  components: {
    StatesChipGroup,
    ModifierOptionSelection,
    ModifierOption,
    DialogWindow,
    UniversalButton,
  },
  props: {
    restaurantPk: {
      type: [Number, String],
      default: '',
    },
    modifier: {
      type: Object,
      default: () => ({}),
    },
    modifiers: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      modifierToEdit: {
        title: '',
        state: 'active',
        isCategory: false,
        isModifier: true,
        options: [],
      },
      options: [],
      selectedOptions: [],
      key: 0,
      addOptionTitle: 'Добавить опцию модификатора',
      rules: [
        (value) => !!value || 'Введите название',
        (value) => !this.alreadyExists(value) || 'Модификатор с таким названием уже существует',
      ],
      dragndropIcon,
      over: {},
      startLoc: 0,
      dragging: false,
      dragable: false,
      dragFrom: {},
    };
  },
  async mounted() {
    if (Object.keys(this.modifier).length) {
      this.modifierToEdit = { ...JSON.parse(JSON.stringify(this.modifier)) };
    }
    this.options = await fetchOptions(this.restaurantPk);
    if (this.modifier.options) {
      this.modifier.options.forEach((option) => this.removeOptionFromSelection(option));
    }
  },
  methods: {
    removeOptionFromSelection(option) {
      const index = this.options.indexOf(option);
      if (index > -1) {
        this.options.splice(index, 1);
      }
    },
    updateModifierOptions() {
      this.selectedOptions.forEach((option) => {
        this.modifierToEdit.options.push(option);
        this.removeOptionFromSelection(option);
      });
      this.updateModifierPosition();
    },
    deleteOption(index, option) {
      this.modifierToEdit.options.splice(index, 1);
      this.options.push(option);
    },
    alreadyExists(title) {
      return this.modifiers.some((modifier) => modifier.title === title);
    },
    startDrag(item, e) {
      this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = item;
    },
    finishDrag(item, pos) {
      this.modifierToEdit.options.splice(pos, 1);
      this.modifierToEdit.options.splice(this.over.pos, 0, item);
      this.over = {};
      this.updateModifierPosition();
    },
    onDragOver(item, pos, e) {
      const dir = (this.startLoc < e.clientY) ? 'down' : 'up';
      setTimeout(() => {
        this.over = { item, pos, dir };
      }, 50);
    },
    updateModifierPosition() {
      if (this.modifierToEdit.options.length > 0) {
        const modifiers = [...[], ...this.modifierToEdit.options];
        modifiers.slice().reverse().forEach((element, index) => {
          // eslint-disable-next-line no-param-reassign
          element.position = index + 1;
        });
        this.modifierToEdit.options = modifiers;
      }
    },
  },
  watch: {
    modifierToEdit: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

.draggable {
  margin: auto 0 auto 10px;
  &:hover {
    cursor: pointer;
  }
  v-img {
    padding-top: 10px;
  }
  span {
    width: 30px;
    font-size: 15px;
    line-height: 18px;
  }
}
.item {
  width: 100%;
  outline: none;
  display: inline-block;
  transition: opacity .3s ease-in-out;
}
</style>
